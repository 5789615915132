<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'categories'}">Categorias</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações da Categoria</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Id</label>
							<input
								v-model.trim="category.id"
								class="form-control"
								disabled="disabled"
							/>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label>Nome*</label>
							<input
								v-model.trim="category.name"
								class="form-control"
								v-uppercase
							/>
						</div>
					</div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Mixins
	import FetchingMixin from "@/mixins/FetchingMixin";
	import Swal from "sweetalert2";
	import {exibeErro, sanitize} from "@/helpers";

	//Aux
	import EjButton from "@/components/EjButton";
	// const _ = require('lodash');

	export default {
		name: 'ProductEdit',
		components: {EjButton},

		data() {
			return {
				loadingRule: false,
				saving: false,
				operation: undefined,

				category: {
					name: undefined,
					brand: undefined,
				},
			}
		},

		computed: {
			spinner() {
				return this.loadingRule;
			},

		},

		mixins: [
			FetchingMixin
		],

		methods: {
			salvar() {
				const vm = this;

				this.saving = true;
				switch (this.operation) {
					case 'create':
						this.$store.dispatch(`categories/post`, sanitize(this.category))
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Categoria criada com sucesso!',
									'success'
								).then(function(result) {
									if (result.isConfirmed)
										window.location = '/categories';
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;

					case 'edit':
						this.$store.dispatch(`categories/patch`, {
							id: this.category.id,
							data: sanitize(this.category)
						})
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Categoria atualizada com sucesso!',
									'success'
								);
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;
				}
			}
		},

		mounted() {
			this.operation = this.$route.name === 'categories.edit' ? 'edit' : 'create';

			const vm = this;

			if (this.operation === 'edit') {
				this.loadingRule = true;
				this.$store.dispatch(`categories/get`, vm.$route.params.id)
					.then(function (response) {
						vm.category = Object.assign(vm.category, response.data);
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingRule = false;
					});
			}
		}
	}
</script>

<style scoped>
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}
	}
</style>
